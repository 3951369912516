let viewportUnitsBuggyfill = require('viewport-units-buggyfill');
viewportUnitsBuggyfill.init();

import Fader from './Fader.js';
import Loader from './Loader.js';
import LangNav from './LangNav.js';
import Footer from './Footer.js';
import Nav from './Nav.js';
import Home from './Home.js';
import YearList from './YearList.js';
import WorksDetail from './WorksDetail.js';
import SmallIconView from './pixi/SmallIconView.js';
import Map from './pixi/Map.js';

(function() {
  var requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;
  window.requestAnimationFrame = requestAnimationFrame;
})();

class App
{
  // ---------------------------------------------------------------------------------------------------------
	// --	App
	// ---------------------------------------------------------------------------------------------------------

  constructor(){

    //フェーダー生成
    this.fader = new Fader();

    //ローダー生成
    this.loader = new Loader();

    //ナビゲーション生成
    this.nav = new Nav();

    //言語選択ナビ
    this.lang = new LangNav();

    //フッター生成
    this.footer = new Footer();

    //ワークリスト
    if($("ul.year-list").length) this.yearlist = new YearList();

    //WORKS詳細ヘッダ
    if($("body").is("#works-detail")) this.detail = new WorksDetail();

    //HOME生成
    if($("body").is("#home"))
    {
      this.home = new Home();
      this.nav.on("CONTACT_SELECTED", ()=>{
        this.home.jumpToContact();
      });
    }
    //Smallアイコン生成
    else
    {
      this.logo = new SmallIconView();
      this.logo.on("ICON_SELECT", (data)=>{
        this.fader.leave(data);
      });
    }

    //MAP生成
    if($("body").is("#who-we-are"))
    {
      this.map = new Map();
    }

    //ローディング終了
    window.onload = ()=>
    {
      //詳細の初期化
      if(this.detail) this.detail.init();

      //年絞り込みUIの初期化
      if(this.yearlist) this.yearlist.init();

      //ローダーを非表示にする
      this.loader.once("finish-loading", this.init.bind(this));
      this.loader.once("loader-leaved", this.start.bind(this));
      this.loader.readyForLeave();
    }
  }


  init()
  {
    //背景色変更
    $("body").css("#FFFFFF");

    //HOMEの場合
    if($("body").is("#home"))
    {
      this.home.init();
    }
    else
    {
      this.logo.init();
    }

    //表示
    $("#main-wrapper").css("opacity", 1.0);
    $("footer").css("opacity", 1.0);
    $(".wpm-language-switcher").css("opacity", 1.0);
    $("#header-base").css("opacity", 1.0);
    $(".btn-burger").css("opacity", 1.0);

    //ローダーリーブ
    this.loader.leave();

    //HOME以外の時はスクロールをハンドリング
    if(!$("body").is("#home")){
      this.before = 0;
      $(window).scroll(this.onScroll.bind(this));
    }

    //ページ内リンク初期化
    this.initInnerLinks();

    //モバイル用のホバー設定
    $(".js-mobile-hover")
    .bind('touchstart', function(){
        $(this).addClass('hover');
        // $("#debug").text("touchstart");
    }).bind('touchmove', function(){
        $(this).addClass('hover');
        // $("#debug").text("touchmove");
    }).bind('touchend', function(){
        $(this).removeClass('hover');
        // $("#debug").text("touchend");
    });
  }

  /**
   * ページ開始
   */
  start()
  {
    //言語ナビ表示
    this.lang.show(1.2);

    if(this.logo) this.logo.showSplash();

    //HOMEの場合
    if($("body").is("#home"))
    {
      this.home.start();
    }
    //HOME以外の場合はスクロール可能にする
    else
    {
      $("body").css("overflow-y", "auto");
    }
  }


  // ---------------------------------------------------------------------------------------------------------
	// --	NAVIGATION
	// ---------------------------------------------------------------------------------------------------------

  /**
   * ページ内リンクの初期化
   */
  initInnerLinks(){
    const holder = this;
    $("a.js-inner-link").each(function(index){
      const href = $(this).attr("href");
      $(this).click(function(e){
        e.preventDefault();
        const topFlag = ($(this).text() == 'TOP');
        holder.fader.leave({href:href, pageX:e.pageX, pageY:e.pageY-$(window).scrollTop(), top:topFlag});
      });
    });
  }

  // ---------------------------------------------------------------------------------------------------------
	// --	SCROLL処理
	// ---------------------------------------------------------------------------------------------------------

  onScroll(e){

    //下についたかどうかのフラグ
    let docHeight = $(document).innerHeight(); //ドキュメントの高さ
    let windowHeight = $(window).innerHeight(); //ウィンドウの高さ
    let pageBottom = docHeight - windowHeight; //ドキュメントの高さ - ウィンドウの高さ
    let bottomFlag = (pageBottom <= $(window).scrollTop());

    if(bottomFlag)
    {
      this.logo.showHeader();
    }
    else
    {
      let after = $(window).scrollTop();
      if(after < 0) after = 0;
      if(this.before > after) {
        this.logo.showHeader();
      }
      else if(this.before < after) {
        this.logo.hideHeader();
      }
      this.before = after;
    }
  }

  // ---------------------------------------------------------------------------------------------------------
}


$(function(){
  let app = new App();
});
